

export const PetsText = {
	info: {
		cat: {
			pl: <div>
				<h2>Kupowanie kociaka</h2>
				<div>Chcemy aby nasze kocięta trafiały do osób odpowiedzialnych i kochających zwierzęta.</div>
				<div>Kot to nie zabawka, musimy pamiętać, że będzie nam towarzyszył przez co najmniej kilkanaście lat.</div>
				<div>Kocięta mogą zamieszkać w nowym domu po ukończeniu 13 tygodni życia.</div>
				<div>Kociątka są nauczone korzystania z kuwety, zaszczepione, odrobaczone, posiadają książeczkę zdrowia oraz rodowód 'non-active' wystawiony przez</div>
				<div><img src="./assets/logo_tica.png" /></div>
			</div>,
			en: <div>
				<h2>Buying a kitten</h2>
				<div>We want our kittens to go to loving and caring family.</div>
				<div>Cat is not a toy. We must remember that it will be with us for many years.</div>
				<div>Kittens are ready to go to their new homes no earlier than 13 weeks.</div>
				<div>They will be fully vaccinated, dewormed, litter trained & registered (as non-active) with the</div>
				<div><img src="./assets/logo_tica.png" /></div>
			</div>
		},
		dog: {
			pl: <div>
				<h2>Kupowanie szczeniaka</h2>
				<div>Chcemy aby nasze szczeniaki trafiały do osób odpowiedzialnych i kochających zwierzęta.</div>
				<div>Pies to nie zabawka, musimy pamiętać, że będzie nam towarzyszył przez co najmniej kilkanaście lat.</div>
				<div>Szczeniaki mogą zamieszkać w nowym domu po ukończeniu 8 tygodni życia.</div>
				<div>Szczeniaki są nauczone zaszczepione, odrobaczone, posiadają książeczkę zdrowia oraz rodowód wystawiony przez IKC.</div>
			</div>,
			en: <div>
				<h2>Buying a puppy</h2>
				<div>We want our Puppies to go to loving and caring family.</div>
				<div>Dog is not a toy. We must remember that it will be with us for many years.</div>
				<div>Puppies are ready to go to their new homes no earlier than 8 weeks.</div>
				<div>They will be fully vaccinated, dewormed & registered with IKC.</div>
			</div>
		},
	},
	male: {
		pl: "Samiec",
		en: "Male"
	},
	female: {
		pl: "Samica",
		en: "Female"
	},
	parent: {
		male: {
			pl: "Ojciec",
			en: "Sire"
		},
		female: {
			pl: "Matka",
			en: "Dam"
		}
	},
	no_image: {
		pl: "Brak zdjęcia",
		en: "No image"
	},
};

